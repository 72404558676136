import React, { useState, useContext, useEffect } from "react";
import "./Stocks.css";
import arrowImage from "../../assets/arrow.svg";
import funnelIcon from "../../assets/funnel_icon.svg";
import closeIcon from "../../assets/close.svg";
import CustomDropdown from "./CustomDropdown";
import { ScripContext } from "../context/ScripContext"; // Import the context
import { useSnackbar } from "notistack"; // Import useSnackbar from notistack
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

const Stocks = ({
  indexList,
  symbols,
  onGroupSelect,
  onScripChange,
  closingPrices,
  expectedReturns,
  variances,
  selectedHistory,
  selectedForecast,
  onHistoryChange,
  onForecastChange,
}) => {
  const {
    scrips,
    setScrips,
    quantities,
    setQuantities,
    selectedIndices,
    setSelectedIndices,
    setClosingPrices,
    setCategories, // Add this to set categories
    setExpectedReturns, // Add this to set expected returns
  } = useContext(ScripContext); // Use the context
  const { enqueueSnackbar } = useSnackbar(); // Initialize the snackbar

  const [selectedScrips, setSelectedScrips] = useState(Array(12).fill(""));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingDropdowns, setLoadingDropdowns] = useState(
    Array(12).fill(false)
  ); // Loading state for dropdowns
  const [subStatus, setSubStatus] = useState("0"); // Initialize subStatus with "0" (Free plan) as default

  // // Retrieve sub_status from localStorage
  // const user = JSON.parse(localStorage.getItem("user"));
  // const subStatus = user && user.sub_status !== undefined ? user.sub_status.toString() : "0";

  // console.log("Subscription Status:", subStatus);
  // console.log("User object:", user);

  // Fetch sub_status from the API
  useEffect(() => {
    const fetchSubStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const email = localStorage.getItem("user_email");
        const response = await fetch(
          "https://api.antsanalyzer.com/sub-status",
          {
            method: "POST", // Assuming POST method
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Authorization header
            },
            body: JSON.stringify({ email_id: email }), // Send email in body
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSubStatus(data.sub_status.toString()); // Set sub_status based on the API response
        } else {
          console.error("Failed to fetch sub_status. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching sub_status:", error);
      }
    };

    fetchSubStatus();
  }, []);

  const handleIndexChange = (index, i) => {
    setLoadingDropdown(i, true); // Start loading spinner for the dropdown
    const newSelectedIndices = [...selectedIndices];
    const newSelectedScrips = [...selectedScrips];

    newSelectedIndices[i] = index;
    newSelectedScrips[i] = ""; // Reset the selected symbol

    setSelectedIndices(newSelectedIndices);
    setSelectedScrips(newSelectedScrips);

    onGroupSelect(index, i);
    setLoadingDropdown(i, false); // Stop loading spinner for the dropdown

    // Save to session storage
    saveToSessionStorage(newSelectedIndices, newSelectedScrips);
  };

  const handleScripChange = (scrip, i) => {
    setLoadingDropdown(i, true); // Start loading spinner for the dropdown
    const newSelectedScrips = [...selectedScrips];
    newSelectedScrips[i] = scrip;
    setSelectedScrips(newSelectedScrips);
    onScripChange(scrip, i);
    setLoadingDropdown(i, false); // Stop loading spinner for the dropdown

    // Save to session storage
    saveToSessionStorage(selectedIndices, newSelectedScrips);
  };

  const saveToSessionStorage = (categories, scrips) => {
    const selectedData = categories.map((category, index) => ({
      category,
      scrip: scrips[index],
    }));
    sessionStorage.setItem("selectedStocks", JSON.stringify(selectedData));
    console.log("Selected Data Stored in Session Storage:", selectedData);
  };

  // Function to get available scrips for each dropdown (filter out already selected ones)
  const getAvailableScrips = (i) => {
    const alreadySelected = selectedScrips.filter(
      (scrip, index) => scrip !== "" && index !== i
    );
    return symbols[i].filter((scrip) => !alreadySelected.includes(scrip));
  };

  const setLoadingDropdown = (index, state) => {
    const newLoadingDropdowns = [...loadingDropdowns];
    newLoadingDropdowns[index] = state;
    setLoadingDropdowns(newLoadingDropdowns);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const data = {
      select_history: selectedHistory,
      compute_duration: selectedForecast,
    };
    console.log(data);
  };

  const handleHistoryChange = (event) => {
    const value = parseFloat(event.target.value);
    onHistoryChange(value);
  };

  const handleForecastChange = (event) => {
    const value = parseInt(event.target.value, 10);
    onForecastChange(value);
  };

  const validateScrips = (scrips) => {
    const categories = ["Large Cap", "Mid Cap", "Small Cap"];
    for (const category of categories) {
      const scripsInCategory = scrips.filter(
        (scrip) => scrip.category === category
      );
      const nonEmptyScrips = scripsInCategory.filter(
        (scrip) => scrip.quantity !== null
      );
      const emptyScrips = scripsInCategory.filter(
        (scrip) => scrip.quantity === null
      );

      if (nonEmptyScrips.length === 2 && emptyScrips.length > 1) {
        return `For ${category}, you can only have up to two stocks with quantities and one empty quantity.`;
      }
      if (nonEmptyScrips.length > 2 && emptyScrips.length > 2) {
        return `For ${category}, you can only have up to two stocks with quantities and two empty quantities.`;
      }
    }
    return null;
  };

  const pushToList = () => {
    const selectedCount = selectedScrips.filter((scrip) => scrip !== "").length;

    if (selectedCount >= 2) {
      // Ensure selectedData is properly declared
      const selectedData = selectedScrips.map((scrip, index) => ({
        category: selectedIndices[index] || "", // Fallback for undefined category
        scrip: scrip || "", // Fallback for undefined scrip
        closingPrice: closingPrices[index] || "-", // Fallback for undefined closing price
        expectedReturn: expectedReturns[index] || "-", // Fallback for undefined expected return
      }));

      // Store the data in session storage
      sessionStorage.setItem("selectedStocks", JSON.stringify(selectedData));

      // Log the stored data for confirmation
      console.log("Selected Data Stored in Session Storage:", selectedData);

      setScrips(selectedScrips); // Update the scrips in the context
      setClosingPrices(closingPrices); // Update the closing prices in the context
      setExpectedReturns(expectedReturns); // Update the expected returns in the context
      setCategories(selectedIndices); // Update the categories in the context
      setQuantities(Array(12).fill(null)); // Reset quantities

      enqueueSnackbar("Scrips pushed to list", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    } else {
      enqueueSnackbar(
        "Please select at least 2 Scrip Symbols before pushing to the list",
        {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
    }
  };

  // Flag to track if the message has been shown already
  let hasShownProMessage = false;

  return (
    <div className="stocks">
      <div className="stocks-header">
        <h2 className="stocks-h2">Stocks</h2>
        <div className="funnel-icon-container">
          <img
            src={funnelIcon}
            alt="Funnel"
            className="funnel-icon"
            onClick={openModal}
          />
          <div className="tooltip">Time Period</div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={closeIcon}
                alt="Close"
                className="stocks-close-icon"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              <div className="modal-section">
                <h3 className="stocks-h3">
                  Select Historical Data for Analysis
                </h3>
                <div className="stocks-card">
                  <div className="stocks-radio-group-row">
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={0.3}
                        checked={selectedHistory === 0.3}
                        onChange={handleHistoryChange}
                      />{" "}
                      3 Months
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={0.6}
                        checked={selectedHistory === 0.6}
                        onChange={handleHistoryChange}
                      />{" "}
                      6 Months
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={1}
                        checked={selectedHistory === 1}
                        onChange={handleHistoryChange}
                      />{" "}
                      1 Year
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={3}
                        checked={selectedHistory === 3}
                        onChange={handleHistoryChange}
                      />{" "}
                      3 Years
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-section">
                <h3 className="stocks-h3">Select Forecast Period</h3>
                <div className="stocks-card">
                  <div className="stocks-radio-group-row">
                    <label>
                      <input
                        type="radio"
                        name="forecast-period"
                        value={1}
                        checked={selectedForecast === 1}
                        onChange={handleForecastChange}
                      />{" "}
                      1 Year
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="forecast-period"
                        value={3}
                        checked={selectedForecast === 3}
                        onChange={handleForecastChange}
                      />{" "}
                      3 Years
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="apply-button-container">
              <button className="apply-button" onClick={closeModal}>
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="stocks-table-container">
        <div className="stocks-table">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Scrip Symbol</th>
                <th className="right-align">Closing Price</th>
                <th className="right-align">Expected Return/INR/Mon</th>
                <th className="right-align">Standard Deviation</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 12 }).map((_, i) => (
                <tr
                  key={i}
                  className={
                    subStatus === "0" && i >= 3
                      ? "12 scrips available in pro and premium edition"
                      : ""
                  }
                >
                  {subStatus === "0" && i >= 3 ? (
                    !hasShownProMessage ? (
                      // Show the message for the first restricted row only
                      <>
                        <td colSpan={5} className="only-pro-premium-text">
                          12 scrips available in pro and premium edition
                        </td>
                        {(hasShownProMessage = true)}
                      </>
                    ) : (
                      // Hide the message for subsequent restricted rows
                      <td colSpan={5} className="hidden-row"></td>
                    )
                  ) : (
                    <>
                      <td className="dropdown-cell">
                        {loadingDropdowns[i] ? (
                          <TailSpin color="#00BFFF" height={20} width={20} />
                        ) : (
                          <CustomDropdown
                            options={indexList}
                            selectedValue={selectedIndices[i]}
                            onChange={(value) => handleIndexChange(value, i)}
                            placeholder="Select Index"
                            className={
                              subStatus === "0" && i >= 3
                                ? "disabled-dropdown"
                                : ""
                            }
                          />
                        )}
                      </td>
                      <td className="dropdown-cell">
                        {loadingDropdowns[i] ? (
                          <TailSpin color="#00BFFF" height={20} width={20} />
                        ) : (
                          <CustomDropdown
                            options={getAvailableScrips(i)}
                            selectedValue={selectedScrips[i]}
                            onChange={(value) => handleScripChange(value, i)}
                            placeholder="Select Scrip"
                            className={
                              subStatus === "0" && i >= 3
                                ? "disabled-dropdown"
                                : ""
                            }
                          />
                        )}
                      </td>
                      <td className="right-align">{closingPrices[i]}</td>
                      <td className="right-align">{expectedReturns[i]}</td>
                      <td className="right-align">
                        {variances[i] !== null &&
                        variances[i] !== undefined &&
                        !isNaN(variances[i])
                          ? Math.sqrt(variances[i]).toFixed(6) //*********changes done on 21-october-2024******************
                          : "-"}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="push-button-container">
        <div className="push-button" onClick={pushToList}>
          <span>Push to List</span>
          <img src={arrowImage} alt="Arrow" className="action-arrow" />
        </div>
      </div>
    </div>
  );
};

export default Stocks;
