import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import Navbar from "../navbar/Navbar";
import "./MomentumDiscovery.css";

// Register required Chart.js components
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const MomentumDiscovery = () => {
  const [selectedOption, setSelectedOption] = useState("Large Cap");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [categories, setCategories] = useState({});
  const [chartData, setChartData] = useState(null);
  const [allQuantitiesZero, setAllQuantitiesZero] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch dropdown and categorized data
  useEffect(() => {
    const storedData =
      JSON.parse(sessionStorage.getItem("userPortfolioData")) || {};

    console.log("Retrieved Data from Session Storage:", storedData);

    if (
      !storedData.scrip_name ||
      !storedData.scrip_category ||
      !storedData.scrip_quantity
    ) {
      console.error("Stored data format is incorrect:", storedData);
      return;
    }

    const allZero = storedData.scrip_quantity.every(
      (quantity) => quantity === 0
    );
    setAllQuantitiesZero(allZero);

    const categorizedData = {
      "Large Cap": [],
      "Mid Cap": [],
      "Small Cap": [],
    };

    storedData.scrip_category.forEach((category, index) => {
      const normalizedCategory = category
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());

      if (categorizedData[normalizedCategory]) {
        categorizedData[normalizedCategory].push({
          scrip_name: storedData.scrip_name[index],
          scrip_quantity: storedData.scrip_quantity[index],
        });
      }
    });

    console.log("Categorized Data:", categorizedData);

    setCategories(categorizedData);

    const options = [];
    if (categorizedData["Large Cap"].length > 0) {
      options.push(
        "Combined LCap",
        ...categorizedData["Large Cap"].map((item) => item.scrip_name)
      );
    }
    if (categorizedData["Mid Cap"].length > 0) {
      options.push(
        "Combined MCap",
        ...categorizedData["Mid Cap"].map((item) => item.scrip_name)
      );
    }
    if (categorizedData["Small Cap"].length > 0) {
      options.push(
        "Combined SCap",
        ...categorizedData["Small Cap"].map((item) => item.scrip_name)
      );
    }

    console.log("Dropdown Options:", options);

    setDropdownOptions(options);

    // Set the default selected option to the first dropdown option
    if (options.length > 0) {
      setSelectedOption(options[0]);
    }
  }, []);

  useEffect(() => {
    // Fetch data for the default selected option
    if (dropdownOptions.length > 0 && selectedOption) {
      handleDropdownChange({ target: { value: selectedOption } });
    }
  }, [dropdownOptions, selectedOption]);

  // // Fetch chart data
  // const handleDropdownChange = async (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedOption(selectedValue);
  //   setIsLoading(true);
  //   setErrorMessage("");
  //   setChartData(null);

  //   let requestData = {};
  //   if (selectedValue.includes("Combined")) {
  //     const capType = selectedValue.includes("LCap")
  //       ? "Large Cap"
  //       : selectedValue.includes("MCap")
  //       ? "Mid Cap"
  //       : "Small Cap";

  //     if (categories[capType]?.length > 0) {
  //       requestData = {
  //         scrip_name: categories[capType].map((item) => item.scrip_name),
  //         scrip_quantity: categories[capType].map(
  //           (item) => item.scrip_quantity
  //         ),
  //       };
  //     }
  //   } else {
  //     const selectedScrip = dropdownOptions.find(
  //       (option) => option === selectedValue
  //     );

  //     if (selectedScrip) {
  //       const capType = Object.keys(categories).find((key) =>
  //         categories[key].some((item) => item.scrip_name === selectedScrip)
  //       );

  //       if (capType) {
  //         const scripData = categories[capType].find(
  //           (item) => item.scrip_name === selectedScrip
  //         );

  //         requestData = {
  //           scrip_name: [scripData.scrip_name],
  //           scrip_quantity: [scripData.scrip_quantity],
  //         };
  //       }
  //     }
  //   }

  //   console.log("Request Data Sent to Backend:", requestData);

  //   try {
  //     const response = await fetch("https://api.antsanalyzer.com/graph", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     const data = await response.json();
  //     console.log("Backend Response:", data);

  //     if (!data || !data.L || !data.L.Date || data.L.Date.length === 0) {
  //       setErrorMessage("There is no data for the selected option.");
  //       setIsLoading(false);
  //       return;
  //     }

  //     const safeData = {
  //       Date: data.L.Date || [],
  //       Price: data.L.Price || [],
  //       RSI: data.L.RSI || [],
  //       MACD_Lines: data.L.MACD_Lines || [],
  //       Signal_Lines: data.L.Signal_Lines || [],
  //       MACD_Histograms: data.L.MACD_Histograms || [],
  //     };

  //     console.log("Processed Chart Data:", safeData);

  //     setChartData(safeData);
  //   } catch (error) {
  //     console.error("Error fetching graph data:", error);
  //     setErrorMessage("Failed to fetch data. Please try again later.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleDropdownChange = async (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedOption(selectedValue);
  //   setIsLoading(true);
  //   setErrorMessage("");
  //   setChartData(null);

  //   let requestData = {};
  //   let capKey = ""; // To store the backend key for response processing

  //   if (selectedValue.includes("Combined")) {
  //     // Handle combined selections
  //     if (selectedValue.includes("LCap")) {
  //       capKey = "L";
  //     } else if (selectedValue.includes("MCap")) {
  //       capKey = "M";
  //     } else if (selectedValue.includes("SCap")) {
  //       capKey = "S";
  //     }

  //     const capType =
  //       capKey === "L" ? "Large Cap" : capKey === "M" ? "Mid Cap" : "Small Cap";

  //     if (categories[capType]?.length > 0) {
  //       requestData = {
  //         scrip_name: categories[capType].map((item) => item.scrip_name),
  //         scrip_quantity: categories[capType].map(
  //           (item) => item.scrip_quantity
  //         ),
  //       };
  //     }
  //   } else {
  //     // Handle single scrip selection
  //     const selectedScrip = dropdownOptions.find(
  //       (option) => option === selectedValue
  //     );

  //     if (selectedScrip) {
  //       const capType = Object.keys(categories).find((key) =>
  //         categories[key].some((item) => item.scrip_name === selectedScrip)
  //       );

  //       if (capType) {
  //         capKey =
  //           capType === "Large Cap" ? "L" : capType === "Mid Cap" ? "M" : "S";

  //         const scripData = categories[capType].find(
  //           (item) => item.scrip_name === selectedScrip
  //         );

  //         requestData = {
  //           scrip_name: [scripData.scrip_name],
  //           scrip_quantity: [1], // Hardcoding quantity to 1 for single scrip
  //         };
  //       }
  //     }
  //   }

  //   console.log("Request Data Sent to Backend:", requestData);

  //   try {
  //     const response = await fetch("https://api.antsanalyzer.com/graph", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     const data = await response.json();
  //     console.log("Backend Response:", data);

  //     if (
  //       !data ||
  //       !data[capKey] ||
  //       !data[capKey].Date ||
  //       data[capKey].Date.length === 0
  //     ) {
  //       setErrorMessage("There is no data for the selected option.");
  //       setIsLoading(false);
  //       return;
  //     }

  //     // Extract the correct data for the chart
  //     const capData = data[capKey];
  //     const safeData = {
  //       Date: capData.Date || [],
  //       Price: capData.Price || [],
  //       RSI: capData.RSI || [],
  //       MACD_Lines: capData.MACD_Lines || [],
  //       Signal_Lines: capData.Signal_Lines || [],
  //       MACD_Histograms: capData.MACD_Histograms || [],
  //     };

  //     console.log("Processed Chart Data:", safeData);

  //     setChartData(safeData);
  //   } catch (error) {
  //     console.error("Error fetching graph data:", error);
  //     setErrorMessage("Failed to fetch data. Please try again later.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleDropdownChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setIsLoading(true);
    setErrorMessage("");
    setChartData(null);

    let requestData = {};
    let capKey = ""; // To store the backend key for response processing

    if (selectedValue.includes("Combined")) {
      // Handle combined selections
      if (selectedValue.includes("LCap")) {
        capKey = "L";
      } else if (selectedValue.includes("MCap")) {
        capKey = "M";
      } else if (selectedValue.includes("SCap")) {
        capKey = "S";
      }

      const capType =
        capKey === "L" ? "Large Cap" : capKey === "M" ? "Mid Cap" : "Small Cap";

      if (categories[capType]?.length > 0) {
        // Check if any scrip quantity is 0
        const hasZeroQuantity = categories[capType].some(
          (item) => item.scrip_quantity === 0
        );

        if (hasZeroQuantity) {
          setErrorMessage(
            "You can't see graphs because you entered 0 quantities/Units for some of the scrips. 0 quantities are not allowed for the Combined Caps."
          );
          setIsLoading(false);
          return;
        }

        requestData = {
          scrip_name: categories[capType].map((item) => item.scrip_name),
          scrip_quantity: categories[capType].map(
            (item) => item.scrip_quantity
          ),
        };
      }
    } else {
      // Handle single scrip selection
      const selectedScrip = dropdownOptions.find(
        (option) => option === selectedValue
      );

      if (selectedScrip) {
        const capType = Object.keys(categories).find((key) =>
          categories[key].some((item) => item.scrip_name === selectedScrip)
        );

        if (capType) {
          capKey =
            capType === "Large Cap" ? "L" : capType === "Mid Cap" ? "M" : "S";

          const scripData = categories[capType].find(
            (item) => item.scrip_name === selectedScrip
          );

          requestData = {
            scrip_name: [scripData.scrip_name],
            scrip_quantity: [1], // Hardcoding quantity to 1 for single scrip
          };
        }
      }
    }

    console.log("Request Data Sent to Backend:", requestData);

    try {
      const response = await fetch("https://api.antsanalyzer.com/graph", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      console.log("Backend Response:", data);

      if (
        !data ||
        !data[capKey] ||
        !data[capKey].Date ||
        data[capKey].Date.length === 0
      ) {
        setErrorMessage("There is no data for the selected option.");
        setIsLoading(false);
        return;
      }

      // Extract the correct data for the chart
      const capData = data[capKey];
      const safeData = {
        Date: capData.Date || [],
        Price: capData.Price || [],
        RSI: capData.RSI || [],
        MACD_Lines: capData.MACD_Lines || [],
        Signal_Lines: capData.Signal_Lines || [],
        MACD_Histograms: capData.MACD_Histograms || [],
      };

      console.log("Processed Chart Data:", safeData);

      setChartData(safeData);
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setErrorMessage("Failed to fetch data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Updated Chart Data:", chartData);
  }, [chartData]);

  // Chart data definitions
  const priceChartData = chartData?.Date
    ? {
        labels: chartData.Date,
        datasets: [
          {
            label: "Price",
            data: chartData.Price,
            borderColor: "blue",
            backgroundColor: "transparent",
            borderWidth: 1.5,
            tension: 0.1,
          },
        ],
      }
    : null;

  const rsiChartData = chartData?.Date
    ? {
        labels: chartData.Date,
        datasets: [
          {
            label: "RSI",
            data: chartData.RSI,
            borderColor: "purple",
            backgroundColor: "transparent",
            borderWidth: 1.5,
            tension: 0.1,
          },
          {
            label: "Overbought Level",
            data: Array(chartData.Date.length).fill(70),
            borderColor: "red",
            borderWidth: 1,
            borderDash: [5, 5],
          },
          {
            label: "Oversold Level",
            data: Array(chartData.Date.length).fill(30),
            borderColor: "green",
            borderWidth: 1,
            borderDash: [5, 5],
          },
        ],
      }
    : null;

  const macdChartData = chartData?.Date
    ? {
        labels: chartData.Date,
        datasets: [
          {
            type: "line",
            label: "MACD Line",
            data: chartData.MACD_Lines,
            borderColor: "blue",
            backgroundColor: "transparent",
            borderWidth: 1.5,
            tension: 0.1,
          },
          {
            type: "line",
            label: "Signal Line",
            data: chartData.Signal_Lines,
            borderColor: "orange",
            backgroundColor: "transparent",
            borderWidth: 1.5,
            tension: 0.1,
          },
          {
            type: "bar",
            label: "MACD Histogram (Positive)",
            data: chartData.MACD_Histograms.map((val) => (val > 0 ? val : 0)),
            backgroundColor: "green",
            borderWidth: 1,
          },
          {
            type: "bar",
            label: "MACD Histogram (Negative)",
            data: chartData.MACD_Histograms.map((val) => (val < 0 ? val : 0)),
            backgroundColor: "red",
            borderWidth: 1,
          },
        ],
      }
    : null;

  return (
    <div>
      <Navbar />
      <div className="momentumdiscovery-container-fluid">
        <div className="momentumdiscovery-mb-3-text-center">
          <select
            id="cap-options"
            value={selectedOption}
            onChange={handleDropdownChange}
            className="momentumdiscovery-form-select momentumdiscovery-mx-auto"
          >
            {dropdownOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}

        {errorMessage && (
          <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
        )}

        {chartData && !isLoading && !errorMessage && (
          <>
            <div className="momentumdiscovery-row momentumdiscovery-g-3">
              <div className="momentumdiscovery-col-12">
                <div className="momentumdiscovery-card">
                  <Line
                    data={{
                      labels: chartData.Date,
                      datasets: [
                        {
                          label: "Price",
                          data: chartData.Price,
                          borderColor: "blue",
                          backgroundColor: "transparent",
                          borderWidth: 1.5,
                          tension: 0.1,
                        },
                      ],
                    }}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {/* {priceChartData && (
          <div className="momentumdiscovery-row momentumdiscovery-g-3">
            <div className="momentumdiscovery-col-12">
              <div className="momentumdiscovery-card">
                <Line
                  data={priceChartData}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
            </div>
          </div>
        )} */}

        {rsiChartData && (
          <div className="momentumdiscovery-row momentumdiscovery-g-3">
            <div className="momentumdiscovery-col-12">
              <div className="momentumdiscovery-card">
                <Line
                  data={rsiChartData}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
            </div>
          </div>
        )}

        {macdChartData && (
          <div className="momentumdiscovery-row momentumdiscovery-g-3">
            <div className="momentumdiscovery-col-12">
              <div className="momentumdiscovery-card">
                <Bar
                  data={macdChartData}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MomentumDiscovery;
