// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./Login/Signin";
import SignUp from "./Login/SignUp";
import ForgotPassword from "./Login/ForgotPassword";
import Analyser from "./Dashboard/analyser/Analyser";
import MomemntumDiscovery from "./Dashboard/momentum-discovery/Momentum-Discovery";
import StressTest from "./Dashboard/stresstest/StressTest";
import Pricing from "./Dashboard/payment/Pricing";
import Report from "./Dashboard/report/Report";
import Profile from "./Dashboard/navbar/Profile"; // Import the Profile component
import PrivacyPolicy from "./Dashboard/payment/policy/PrivacyPolicy";
import TermsConditions from "./Dashboard/payment/policy/TermsConditions";
import CancellationRefundPolicy from "./Dashboard/payment/policy/CancellationRefundPolicy";
import Checkout from "./Dashboard/payment/Checkout"; // Adjust the path as necessary
import QRPage from "./Dashboard/payment/QRPage"; // Your QR page component
import ThankYou from "./Dashboard/payment/ThankYou"; // Adjust the path as necessary

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/analyser" element={<Analyser />} />
      <Route path="/momentum-discovery" element={<MomemntumDiscovery />} />
      <Route path="/stresstest" element={<StressTest />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/qr" element={<QRPage />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/report" element={<Report />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
      <Route path="/terms-conditions" element={<TermsConditions />} />{" "}
      <Route
        path="/cancellation-refund-policy"
        element={<CancellationRefundPolicy />}
      />{" "}
    </Routes>
  );
}
