import React, { useState, useEffect, useContext } from "react";
import Navbar from "../navbar/Navbar";
import Stocks from "./Stocks";
import MyList from "./MyList";
import { ScripContext } from "../context/ScripContext";
import "./Analyser.css";
import arrowImage from "../../assets/skipArrow.png"; // Ensure this path is correct
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { useSnackbar } from "notistack"; // Import useSnackbar from notistack
import SubscribeModal from "./SubscribeModal"; // Import the new modal component
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

const Analyser = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [indexList, setIndexList] = useState([]);
  const { closingPrices, setClosingPrices } = useContext(ScripContext); // Use context for closing prices
  const [symbols, setSymbols] = useState(Array(12).fill([]));
  const [selectedGroups, setSelectedGroups] = useState(Array(12).fill(""));
  const [expectedReturns, setExpectedReturns] = useState(Array(12).fill("-"));
  const [variances, setVariances] = useState(Array(12).fill("-"));
  const [selectedHistory, setSelectedHistory] = useState(3); // Default to 1 year
  const [selectedForecast, setSelectedForecast] = useState(1); // Default to 1 year
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [subStatus, setSubStatus] = useState("0"); // Initialize subStatus state
  const [loading, setLoading] = useState(true); // Loading state for loader

  // JWT tokens for auth
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("user_email");
  const navigate = useNavigate(); // Initialize useNavigate
  const { enqueueSnackbar } = useSnackbar(); // Initialize useSnackbar

  // const user = JSON.parse(localStorage.getItem("user")); // Parse the user object
  // const subStatus = user && user.sub_status !== undefined ? user.sub_status.toString() : "0"; // Fallback to "0" if sub_status is missing
  // console.log("Subscription Status:", subStatus);
  // console.log("User object:", user);

  //timer loader
  useEffect(() => {
    // Simulate a 1-second loading time
    setTimeout(() => {
      setLoading(false); // Stop loading after 1 second
    }, 800);
  }, []);

  const openModal = () => {
    setIsModalOpen(true); // Show modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Hide modal
  };

  useEffect(() => {
    const fetchSubStatus = async () => {
      try {
        const url = "https://api.antsanalyzer.com/sub-status"; // API endpoint

        // Prepare the request options
        const requestOptions = {
          method: "POST", // Use POST method to send body
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header
          },
          body: JSON.stringify({ email_id: email }), // Send the email in the body
        };

        // Fetch the sub-status
        const response = await fetch(url, requestOptions);

        if (response.ok) {
          // Parse and log the response body
          const data = await response.json();
          console.log("Response Body:", data);

          // Set the sub_status and log it
          setSubStatus(data.sub_status.toString());
          console.log("Updated sub_status state:", data.sub_status.toString());
        } else {
          // Log if the response status is not OK (e.g., 400 or 500 errors)
          console.error("Failed to fetch sub_status. Status:", response.status);
          const errorBody = await response.text();
          console.error("Error Response Body:", errorBody); // Log the error response body
        }
      } catch (error) {
        // Log any errors that occur during the fetch operation
        console.error("Error fetching sub_status:", error);
      }
    };

    fetchSubStatus();
  }, [email, token]);

  // useEffect(() => {
  //   // Disable right-click
  //   const handleContextMenu = (event) => event.preventDefault();
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // Cleanup: Re-enable right-click on component unmount
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  // useEffect(() => {
  //   // Disable right-click context menu
  //   const handleContextMenu = (event) => event.preventDefault();
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // Disable F12, Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J, Ctrl+U
  //   const handleKeydown = (event) => {
  //     if (
  //       event.keyCode === 123 || // F12
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 67) || // Ctrl+Shift+C
  //       (event.ctrlKey && event.keyCode === 85) // Ctrl+U
  //     ) {
  //       event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeydown);

  //   // Cleanup: Remove event listeners on component unmount
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("keydown", handleKeydown);
  //   };
  // }, []);

  const handleLogout = () => {
    // Perform logout actions here, e.g., clearing tokens, redirecting to login
    localStorage.removeItem("token");
    localStorage.removeItem("user_email");
    enqueueSnackbar("Unauthorised access, Please login", {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
    navigate("/signin");
  };

  const checkResponseStatus = (response) => {
    if (response.status === 403) {
      handleLogout();
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          "https://api.antsanalyzer.com/get-groups",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email_id: email }),
          }
        );

        checkResponseStatus(response);

        if (response.ok) {
          const data = await response.json();
          const groups = data.data
            .map((group) => ({
              name: group.group_name,
              id: group.group_id,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setIndexList(groups);
        } else {
          console.error("Failed to fetch groups");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, [token, email]);

  const fetchSymbols = async (groupId, index) => {
    try {
      const response = await fetch("https://api.antsanalyzer.com/get-symbols", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email_id: email, group_id: groupId }),
      });

      checkResponseStatus(response);

      if (response.ok) {
        const data = await response.json();
        const newSymbols = [...symbols];
        newSymbols[index] = data.data
          .map((symbol) => symbol.symbol)
          .sort((a, b) => a.localeCompare(b));
        setSymbols(newSymbols);
      } else {
        console.error("Failed to fetch symbols");
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const fetchScripData = async (scrip, index) => {
    try {
      const response = await fetch(
        `https://api.antsanalyzer.com/closing-price-returns/${scrip}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      checkResponseStatus(response);

      const result = await response.json();
      const { data } = result;

      const closingPrice = data.last_closing_price;
      let expectedReturn = "-";
      let variance = "-";
      if (selectedHistory === 0.3) {
        expectedReturn = data.ex_3_months;
        variance = data.var_3_months;
      } else if (selectedHistory === 0.6) {
        expectedReturn = data.ex_6_months;
        variance = data.var_6_months;
      } else if (selectedHistory === 1) {
        expectedReturn = data.ex_1_year;
        variance = data.var_1_year;
      } else if (selectedHistory === 3) {
        expectedReturn = data.ex_3_years;
        variance = data.var_3_years;
      }

      const newClosingPrices = [...closingPrices];
      const newExpectedReturns = [...expectedReturns];
      const newVariances = [...variances];

      newClosingPrices[index] = closingPrice;
      newExpectedReturns[index] = expectedReturn;
      newVariances[index] = variance;

      setClosingPrices(newClosingPrices);
      setExpectedReturns(newExpectedReturns);
      setVariances(newVariances);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const binarySearch = (arr, x) => {
    let start = 0;
    let end = arr.length - 1;

    while (start <= end) {
      const mid = Math.floor((start + end) / 2);

      if (arr[mid].name === x) return arr[mid];
      else if (arr[mid].name < x) start = mid + 1;
      else end = mid - 1;
    }

    return null;
  };

  const handleGroupSelect = (group, index) => {
    const selectedGroup = binarySearch(indexList, group);
    if (selectedGroup) {
      const newSelectedGroups = [...selectedGroups];
      newSelectedGroups[index] = group;
      setSelectedGroups(newSelectedGroups);
      fetchSymbols(selectedGroup.id, index);
    }
  };

  const handleScripChange = (scrip, index) => {
    fetchScripData(scrip, index);
  };

  const handleHistoryChange = (value) => {
    setSelectedHistory(value);
    // Optionally, refetch data for all selected scrips if needed
    selectedGroups.forEach((group, index) => {
      if (group !== "") {
        fetchScripData(group, index);
      }
    });
  };

  const handleForecastChange = (value) => {
    setSelectedForecast(value);
  };

  const resetVisibilityTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsVisible(true);
    const newTimeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight;
      setIsAtBottom(bottom);
      resetVisibilityTimeout();
    };

    const handleMouseMove = () => {
      resetVisibilityTimeout();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div className="Analyser-App">
      <Navbar />

      {/* Display the loader backdrop when loading */}
      {loading && (
        <div className="loading-backdrop">
          <div>
            <TailSpin
              height="80"
              width="80"
              color="#fc2400"
              ariaLabel="loading"
            />
            <p className="loading-text">Loading...</p>
          </div>
        </div>
      )}

      {/* Display the content only when loading is false */}
      {!loading && (
        <>
          {/*Conditionally render Trial Edition and Subscribe button if sub_status is 0 */}
          {subStatus === "0" && (
            <div className="trial-edition-container">
              <span className="trial-edition">Trial Edition </span>
              <button onClick={openModal} className="subscribe-link">
                (Click here to Activate)
              </button>
            </div>
          )}

          {/* Modal for subscription */}
          <SubscribeModal showModal={isModalOpen} closeModal={closeModal} />

          {/* <div className="contact-message">
        For assistance, please contact us at{" "}
        <a href="mailto:contactus@antsai.in" className="contact-email">
          contactus@antsai.in
        </a>
      </div> */}
          <div className="Analyser-content">
            <Stocks
              indexList={indexList.map((g) => g.name)}
              symbols={symbols}
              onGroupSelect={handleGroupSelect}
              onScripChange={handleScripChange}
              closingPrices={closingPrices}
              expectedReturns={expectedReturns}
              variances={variances}
              selectedHistory={selectedHistory}
              selectedForecast={selectedForecast}
              onHistoryChange={handleHistoryChange}
              onForecastChange={handleForecastChange}
            />
            <MyList
              selectedHistory={selectedHistory}
              selectedForecast={selectedForecast}
            />
          </div>
          {/* {isVisible && (
        <div
          className="floating-arrow"
          onClick={isAtBottom ? scrollToTop : scrollToBottom}
        >
          <img
            src={arrowImage}
            alt="Arrow"
            className={isAtBottom ? "arrow-up" : "arrow-down"}
          />
        </div>
      )} */}
        </>
      )}
    </div>
  );
};

export default Analyser;
