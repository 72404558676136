// import React from "react";
// import { StrictMode } from "react";
// import { createRoot } from "react-dom/client";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import App from "./App";
// import { ScripProvider } from "./Dashboard/context/ScripContext"; // Adjust the path as necessary

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//   <StrictMode>
//     <ScripProvider>
//       <App />
//     </ScripProvider>
//   </StrictMode>
// );

import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import App from "./App";
import { ScripProvider } from "./Dashboard/context/ScripContext"; // Adjust the path as necessary

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <SnackbarProvider maxSnack={3}>
      <ScripProvider>
        <Router>
          <App />
        </Router>
      </ScripProvider>
    </SnackbarProvider>
  </StrictMode>
);
